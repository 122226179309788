<template>
  <v-dialog
    data-testid="hub-profile-operations-change-to-sub-hub-dialog"
    :value="true"
    :retain-focus="false"
    persistent
    width="750"
  >
    <v-card class="py-6 px-8 shadow-regular">
      <div class="d-flex align-center">
        <span
          data-testid="hub-profile-operations-change-to-sub-hub-title"
          class="heading-h3 mb-3 mt-4"
        >
          {{ t('Title_.subTitle.createSubHub') }}
        </span>
      </div>
      <v-form
        data-testid="hub-profile-operations-change-to-sub-hub-form"
        v-model="isFormValid"
        ref="form"
        class="mt-4"
      >
        <v-container class="pa-0">
          <v-row dense>
            <v-col cols="12">
              <DropdownInput
                data-testid="hub-profile-operations-change-to-sub-hub-billing-hub-dropdown"
                :label="t('Msg_.billingHub') + '*'"
                :items="selectBillingHub"
                v-model="subHubInfo.billingHubId"
                :clearable="false"
                :rules="[
                  rules.fieldRequired(
                    `${t('Msg_.billingHub')} ${t('Msg_.rules.isRequired')}`
                  ),
                ]"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-container class="px-0 pt-6 pb-2">
          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn
                data-testid="hub-profile-operations-change-to-sub-hub-cancel-btn"
                class="main-action-variant-btn"
                @click="closeChangeToSubHubDialog()"
              >
                {{ t('Action_.buttons.cancel') }}
              </v-btn>
              <v-btn
                data-testid="hub-profile-operations-change-to-sub-hub-save-changes-btn"
                class="main-action-btn ml-2"
                :disabled="!isFormValid"
                @click="addSubHubInfo()"
              >
                {{ t('Action_.buttons.confirm') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script setup>
import DropdownInput from '@/components/shared/customComponents/inputs/dropdown-input.vue';

import rules from '@/helpers/rules';
import languages from '../../locales/languages';
import { useHubsStore } from '@/store/pinia/hubs.store.js';
import { defineEmits, ref, computed } from 'vue';
import { useI18n } from 'vue-i18n-bridge';

const hubsStore = useHubsStore();
const emit = defineEmits([
  'add-sub-hub-info',
  'close-change-to-sub-hub-dialog',
]);
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Local variables ---
const isFormValid = ref(false);
const subHubInfo = ref({
  billingHubId: null,
});

// --- Methods ---
const closeChangeToSubHubDialog = () => {
  emit('close-change-to-sub-hub-dialog');
};

const addSubHubInfo = () => {
  emit('add-sub-hub-info', subHubInfo.value);
};

// --- Computed ---
const selectBillingHub = computed(() => {
  const billingHubs = hubsStore.billingHubs?.map((billingHub) => {
    return { value: billingHub.id, text: billingHub.name };
  });
  return billingHubs;
});
</script>

<style lang="scss" scoped></style>
