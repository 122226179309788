<template>
  <v-expansion-panels
    data-testid="hub-profile-operations-general-container"
    class="shadow-regular"
    :value="open"
    flat
  >
    <v-expansion-panel class="rounded-lg pa-2">
      <v-expansion-panel-header class="heading-h3">
        <div class="d-flex align-center">
          <span data-testid="hub-profile-operations-general-title" class="mr-2">
            {{ t('Title_.subTitle.general') }}
          </span>
        </div>
        <template #actions>
          <v-icon
            data-testid="hub-profile-operations-general-chevron-icon"
            color="#212121"
          >
            mdi-chevron-down
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form
          data-testid="hub-profile-operations-general-form"
          v-model="isFormValid"
          ref="form"
          class="mt-4"
        >
          <v-container class="pa-0">
            <v-row dense>
              <v-col cols="12">
                <DropdownInput
                  data-testid="hub-profile-operations-general-hub-type-dropdown"
                  :label="t('Msg_.hubType') + '*'"
                  :items="selectHubType"
                  :disabled="isFormDisabled || isBillingHubInHubInfo"
                  v-model="hubGeneralInfo.hubType"
                  :hint="handleHubTypeHint"
                  is-hint-persistent
                  :clearable="false"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.hubType')} ${t('Msg_.rules.isRequired')}`
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense v-if="isSubHubInHubInfo">
              <v-col cols="12">
                <DropdownInput
                  data-testid="hub-profile-operations-general-billing-hub-dropdown"
                  :label="t('Msg_.billingHub') + '*'"
                  :items="selectBillingHub"
                  :disabled="isFormDisabled"
                  v-model="hubGeneralInfo.billingHubId"
                  :clearable="false"
                  @change="getCompanyForSubhub()"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.billingHub')} ${t('Msg_.rules.isRequired')}`
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <DropdownInput
                  v-if="hasHubTypeInHubInfo"
                  data-testid="hub-profile-operations-general-company-dropdown"
                  :label="t('Msg_.company') + '*'"
                  :items="selectCompany"
                  :disabled="isFormDisabled || isSubHubInHubInfo"
                  v-model="hubGeneralInfo.companyId"
                  :clearable="false"
                  :rules="handleCompanyFieldRules"
                />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <DatePicker
                  data-testid="hub-profile-operations-general-operation-start-date-picker"
                  class="pr-3"
                  :disabled="isFormDisabled"
                  :label="t('Msg_.operationStart') + '*'"
                  :placeholder="t('Msg_.datePlaceholder')"
                  v-model="hubGeneralInfo.operationStartDate"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.operationStart')} ${t(
                        'Msg_.rules.isRequired'
                      )}`
                    ),
                  ]"
                />
              </v-col>
              <v-col cols="6">
                <DatePicker
                  data-testid="hub-profile-operations-general-operation-end-date-picker"
                  class="pl-3"
                  :disabled="isFormDisabled"
                  :label="t('Msg_.operationEnd')"
                  :placeholder="t('Msg_.datePlaceholder')"
                  v-model="hubGeneralInfo.operationEndDate"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <RegularTextInput
                  class="mb-1"
                  data-testid="hub-profile-operations-general-status-field"
                  :label="t('Msg_.operationStatus')"
                  disabled
                  :value="getOperationStatus"
                  :hint="handleOperationStatusHint"
                  is-hint-persistent
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <DropdownInput
                  data-testid="hub-profile-operations-general-growth-type-dropdown"
                  :label="t('Msg_.growthType') + '*'"
                  :items="selectGrowthType"
                  :disabled="isFormDisabled"
                  v-model="hubGeneralInfo.growthType"
                  :clearable="false"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.growthType')} ${t('Msg_.rules.isRequired')}`
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <RegularTextInput
                  data-testid="hub-profile-operations-general-operating-number-field"
                  :label="t('Msg_.operatingNumber')"
                  :disabled="isFormDisabled"
                  v-model="hubGeneralInfo.operationNumber"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <DropdownInput
                  data-testid="hub-profile-operations-general-storage-location-dropdown"
                  :label="t('Msg_.storageLocation')"
                  :items="selectStorageLocationType"
                  :disabled="isFormDisabled"
                  v-model="hubGeneralInfo.storageLocation"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <DropdownInput
                  data-testid="hub-profile-operations-general-city-bonus-dropdown"
                  :label="t('Msg_.cityBonus')"
                  :items="selectCityBonusType"
                  :disabled="isFormDisabled"
                  v-model="hubGeneralInfo.cityBonus"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <RegularTextInput
                  data-testid="hub-profile-operations-general-cost-center-field"
                  :label="t('Msg_.costCenter')"
                  :disabled="isFormDisabled"
                  v-model="hubGeneralInfo.costCenter"
                />
              </v-col>
            </v-row>
          </v-container>
          <v-container
            v-if="canUser(['Hubs.UpdateOperationsGeneral'])"
            class="px-0 pt-6 pb-2"
          >
            <v-row>
              <v-col v-if="isFormDisabled" cols="12" class="d-flex justify-end">
                <v-btn
                  data-testid="hub-profile-operations-general-edit-info-btn"
                  class="main-action-btn"
                  @click="toggleDisabled()"
                >
                  {{ t('Action_.buttons.editInformation') }}
                </v-btn>
              </v-col>
              <v-col
                v-if="!isFormDisabled"
                cols="12"
                class="d-flex justify-end"
              >
                <v-btn
                  data-testid="hub-profile-operations-general-cancel-btn"
                  class="main-action-variant-btn"
                  @click="displayConfirmationDialog()"
                >
                  {{ t('Action_.buttons.cancel') }}
                </v-btn>
                <v-btn
                  data-testid="hub-profile-operations-general-save-changes-btn"
                  class="main-action-btn ml-2"
                  :disabled="!isFormValid"
                  @click="handleUpdateHubGeneralInfo()"
                >
                  {{ t('Action_.buttons.saveChanges') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <HubProfileOperationsChangeToBillingHubDialog
      v-if="displayChangeToBillingHubDialog"
      :is-sub-hub-to-billing-hub="isSubHubToBillingHub"
      @close-change-to-billing-hub-dialog="closeChangeToBillingHubDialog()"
      @add-billing-hub-info="addBillingHubInfo"
    />
    <HubProfileOperationsChangeToSubHubDialog
      v-if="displayChangeToSubHubDialog"
      @close-change-to-sub-hub-dialog="closeChangeToSubHubDialog()"
      @add-sub-hub-info="addSubHubInfo"
    />
  </v-expansion-panels>
</template>

<script setup>
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';
import DropdownInput from '@/components/shared/customComponents/inputs/dropdown-input.vue';
import DatePicker from '@/components/shared/customComponents/inputs/date-picker.vue';
import HubProfileOperationsChangeToBillingHubDialog from './hub-profile-operations-change-to-billing-hub-dialog.vue';
import HubProfileOperationsChangeToSubHubDialog from './hub-profile-operations-change-to-sub-hub-dialog.vue';

import rules from '@/helpers/rules';
import helpers from '@/helpers/helpers';
import languages from '../../locales/languages';
import { useI18n } from 'vue-i18n-bridge';
import { usePermissions } from '@/helpers/composables/permissions.js';
import { useRoute, onBeforeRouteLeave } from 'vue-router/composables';
import { useHubsStore } from '@/store/pinia/hubs.store.js';
import { usePeopleStore } from '@/store/pinia/people.store.js';
import { usePopupDialogStore } from '@/store/pinia/popup-dialog.store.js';
import { ref, computed, onBeforeUnmount } from 'vue';

const form = ref();
const route = useRoute();
const hubsStore = useHubsStore();
const peopleStore = usePeopleStore();
const popupDialogStore = usePopupDialogStore();
const { canUser } = usePermissions();
const { t, tm } = useI18n({ locale: 'en', messages: languages });

// --- Local variables ---
const open = ref(0);
const isFormValid = ref(false);
const isFormDisabled = ref(true);
const unchangedForm = ref(null);
const nextRoute = ref(null);
const hubGeneralInfo = ref({
  hubType: null,
  billingHubId: null,
  companyId: null,
  operationStartDate: null,
  operationEndDate: null,
  operationStatus: null,
  growthType: null,
  operationNumber: null,
  storageLocation: null,
  cityBonus: null,
  costCenter: null,
});
const displayChangeToBillingHubDialog = ref(false);
const displayChangeToSubHubDialog = ref(false);
const isSubHubToBillingHub = ref(false);

// --- Methods ---
const getHubGeneralInfoById = async () => {
  try {
    hubGeneralInfo.value = await hubsStore.getHubGeneralInfoById(
      route.params.hubId
    );
    form.value.resetValidation();
  } catch (error) {
    console.log(error);
  }
};

const getHubMenuInformation = async () => {
  try {
    await hubsStore.getHubInfoById(route.params.hubId);
  } catch (error) {
    console.log(error);
  }
};

const updateHubGeneralInfoById = async () => {
  try {
    const payload = {
      hubId: route.params.hubId,
      hubGeneralInfo: hubGeneralInfo.value,
    };
    await hubsStore.updateHubGeneralInfoById(payload);
    await getHubMenuInformation();
    await getHubGeneralInfoById();
    assignFormData();
  } catch (error) {
    console.log(error);
  }
  toggleDisabled();
};

const handleUpdateHubGeneralInfo = () => {
  if (isBillingHub.value && wasSubHub.value) {
    isSubHubToBillingHub.value = true;
    displayChangeToBillingHubDialog.value = true;
  } else if (isBillingHub.value && hasHubType.value) {
    isSubHubToBillingHub.value = false;
    displayChangeToBillingHubDialog.value = true;
  } else if (isSubHub.value && hasHubType.value) {
    displayChangeToSubHubDialog.value = true;
  } else {
    updateHubGeneralInfoById();
  }
};

const getCompanyForSubhub = () => {
  if (isSubHub.value) {
    const matchingBillingHub = peopleStore.peopleHubList?.find(
      (hub) => hub.billingHubId === hubGeneralInfo.value.billingHubId
    );
    if (matchingBillingHub) {
      hubGeneralInfo.value.companyId = matchingBillingHub.companyId;
    }
  }
};

const addBillingHubInfo = (payload) => {
  hubGeneralInfo.value = { ...hubGeneralInfo.value, ...payload };
  updateHubGeneralInfoById();
  closeChangeToBillingHubDialog();
};

const closeChangeToBillingHubDialog = () => {
  displayChangeToBillingHubDialog.value = false;
};

const addSubHubInfo = (payload) => {
  hubGeneralInfo.value = { ...hubGeneralInfo.value, ...payload };
  updateHubGeneralInfoById();
  closeChangeToSubHubDialog();
};

const closeChangeToSubHubDialog = () => {
  displayChangeToSubHubDialog.value = false;
};

const toggleDiscardChangesDialog = (next) => {
  const actionBtn = () => {
    discardChanges();
    if (nextRoute.value) next();
  };

  const params = {
    type: 'confirmation',
    title: t('Action_.dialog.saveChanges.title'),
    message: t('Action_.dialog.saveChanges.body'),
    actionBtnText: t('Action_.buttons.proceed'),
    actionBtn: actionBtn,
  };

  popupDialogStore.params = params;
  popupDialogStore.displayDialog = true;
};

const isFormAltered = () => {
  if (unchangedForm.value) {
    return !helpers.compareTwoObjects(
      hubGeneralInfo.value,
      unchangedForm.value
    );
  }
  return false;
};

const assignFormData = () => {
  unchangedForm.value = { ...{}, ...hubGeneralInfo.value };
};

const clearNextRouteData = () => {
  nextRoute.value = null;
};

const discardChanges = () => {
  hubGeneralInfo.value = unchangedForm.value;
  toggleDisabled();
};

const toggleDisabled = () => {
  assignFormData();
  form.value.validate();
  isFormDisabled.value = !isFormDisabled.value;
};

const displayConfirmationDialog = () => {
  clearNextRouteData();
  if (isFormAltered()) {
    toggleDiscardChangesDialog();
  } else {
    toggleDisabled();
  }
};

const initializeComponent = async () => {
  await getHubGeneralInfoById();
  getCompanyForSubhub();
  assignFormData();
};

// --- Computed ---
const isBillingHub = computed(() => {
  return hubGeneralInfo.value.hubType === 1;
});

const isSubHub = computed(() => {
  return hubGeneralInfo.value.hubType === 2;
});

const isBillingHubInHubInfo = computed(() => {
  return hubsStore.hubInfo.hubType === 1;
});

const isSubHubInHubInfo = computed(() => {
  return hubsStore.hubInfo.hubType === 2;
});

const wasSubHub = computed(() => {
  return unchangedForm.value.hubType === 2;
});

const hasHubType = computed(() => {
  return (
    unchangedForm.value.hubType === null || unchangedForm.value.hubType === 0
  );
});

const hasHubTypeInHubInfo = computed(() => {
  return !!hubsStore.hubInfo.hubType;
});

const handleHubTypeHint = computed(() => {
  return isBillingHubInHubInfo.value && !isFormDisabled.value
    ? t('Msg_.billingHubMessage')
    : '';
});

const handleCompanyFieldRules = computed(() => {
  return isSubHubInHubInfo
    ? []
    : [
        rules.fieldRequired(
          `${t('Msg_.billingHub')} ${t('Msg_.rules.isRequired')}`
        ),
      ];
});

const handleOperationStatusHint = computed(() => {
  return !isFormDisabled.value ? t('Msg_.operationStatusMessage') : '';
});

const selectHubType = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectHubTypes'),
    peopleStore.peopleResources?.hubTypes
  );
});

const selectGrowthType = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectGrowthTypes'),
    peopleStore.peopleResources?.growthTypes
  );
});

const selectStorageLocationType = computed(() => {
  const storageLocationTypes =
    peopleStore.peopleResources?.storageLocationTypes.map((location) => {
      return { value: location.Value, text: location.Name };
    });
  return storageLocationTypes;
});

const selectCityBonusType = computed(() => {
  const cityBonusTypes = peopleStore.peopleResources?.cityBonusTypes.map(
    (bonus) => {
      return { value: bonus.Value, text: bonus.Name };
    }
  );
  return cityBonusTypes;
});

const selectBillingHub = computed(() => {
  const billingHubs = hubsStore.billingHubs?.map((billingHub) => {
    return { value: billingHub.id, text: billingHub.name };
  });
  return billingHubs;
});

const selectCompany = computed(() => {
  const companies = hubsStore.companies?.items.map((company) => {
    return { value: company.id, text: company.name };
  });
  return companies;
});

const getOperationStatus = computed(() => {
  const today = new Date();
  const startDate = hubGeneralInfo.value.operationStartDate
    ? new Date(hubGeneralInfo.value.operationStartDate)
    : null;
  const endDate = hubGeneralInfo.value.operationEndDate
    ? new Date(hubGeneralInfo.value.operationEndDate)
    : null;

  if (startDate === null) {
    return null;
  } else if (startDate > today) {
    return tm('Action_.selectOperationTypes[11]'); // in preparation
  } else if (startDate < today && (endDate === null || endDate > today)) {
    return tm('Action_.selectOperationTypes[21]'); // active
  } else if (endDate < today) {
    return tm('Action_.selectOperationTypes[201]'); // inactive
  }

  return null; // fallback to null if none of the conditions are met
});

// --- Lifecycle hooks ---
initializeComponent();

onBeforeUnmount(() => {
  clearNextRouteData();
});

// --- Routing guard ---
onBeforeRouteLeave((to, _, next) => {
  clearNextRouteData();
  if (!isFormAltered()) {
    nextRoute.value = to;
  }
  if (nextRoute.value) {
    next();
  } else {
    nextRoute.value = to;
    toggleDiscardChangesDialog(next);
  }
});
</script>

<style scoped lang="scss"></style>
