<template>
  <v-expansion-panels
    data-testid="hub-profile-operations-billing-hub-container"
    class="shadow-regular"
    :value="open"
    flat
  >
    <v-expansion-panel class="rounded-lg pa-2">
      <v-expansion-panel-header class="heading-h3">
        <div class="d-flex align-center">
          <span
            data-testid="hub-profile-operations-billing-hub-title"
            class="mr-2"
          >
            {{ t('Title_.subTitle.billingHub') }}
          </span>
        </div>
        <template #actions>
          <v-icon
            data-testid="hub-profile-operations-billing-hub-chevron-icon"
            color="#212121"
          >
            mdi-chevron-down
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form
          data-testid="hub-profile-operations-billing-hub-form"
          v-model="isFormValid"
          ref="form"
          class="mt-4"
        >
          <v-container class="pa-0">
            <v-row dense>
              <v-col cols="12">
                <RegularTextInput
                  data-testid="hub-profile-operations-billing-hub-ik-number-field"
                  :label="t('Msg_.ikNumber') + '*'"
                  :disabled="isFormDisabled"
                  v-model="billingHubInfo.ikNumber"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.ikNumber')} ${t('Msg_.rules.isRequired')}`
                    ),
                    rules.totalSize(9, t('Msg_.rules.mustHaveNineDigits')),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <DatePicker
                  data-testid="hub-profile-operations-billing-hub-ik-sgb-xi-date-picker"
                  class="pr-3"
                  :disabled="isFormDisabled"
                  :label="t('Msg_.ikSgbXIStartDate') + '*'"
                  :placeholder="t('Msg_.datePlaceholder')"
                  v-model="billingHubInfo.iksgvxiStartDate"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.ikSgbXIStartDate')} ${t(
                        'Msg_.rules.isRequired'
                      )}`
                    ),
                  ]"
                />
              </v-col>
              <v-col cols="6">
                <DatePicker
                  data-testid="hub-profile-operations-billing-hub-operation-ik-sgb-v-date-picker"
                  class="pl-3"
                  :disabled="isFormDisabled"
                  :label="t('Msg_.ikSgbVStartDate') + '*'"
                  :placeholder="t('Msg_.datePlaceholder')"
                  v-model="billingHubInfo.iksgbvStartDate"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.ikSgbVStartDate')} ${t(
                        'Msg_.rules.isRequired'
                      )}`
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <DropdownInput
                  data-testid="hub-profile-operations-billing-hub-bpa-membership-dropdown"
                  :label="t('Msg_.bpaMembership') + '*'"
                  :items="selectBpaMembershipType"
                  :disabled="isFormDisabled"
                  v-model="billingHubInfo.bpaMembership"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.bpaMembership')} ${t('Msg_.rules.isRequired')}`
                    ),
                  ]"
                />
              </v-col>
            </v-row>
          </v-container>
          <v-container
            v-if="canUser(['Hubs.UpdateOperationsBillingHub'])"
            class="px-0 pt-6 pb-2"
          >
            <v-row>
              <v-col v-if="isFormDisabled" cols="12" class="d-flex justify-end">
                <v-btn
                  data-testid="hub-profile-operations-billing-hub-edit-info-btn"
                  class="main-action-btn"
                  @click="toggleDisabled()"
                >
                  {{ t('Action_.buttons.editInformation') }}
                </v-btn>
              </v-col>
              <v-col
                v-if="!isFormDisabled"
                cols="12"
                class="d-flex justify-end"
              >
                <v-btn
                  data-testid="hub-profile-operations-billing-hub-cancel-btn"
                  class="main-action-variant-btn"
                  @click="displayConfirmationDialog()"
                >
                  {{ t('Action_.buttons.cancel') }}
                </v-btn>
                <v-btn
                  data-testid="hub-profile-operations-billing-hub-save-changes-btn"
                  class="main-action-btn ml-2"
                  :disabled="!isFormValid"
                  @click="updateBillingHubInfoById()"
                >
                  {{ t('Action_.buttons.saveChanges') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script setup>
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';
import DropdownInput from '@/components/shared/customComponents/inputs/dropdown-input.vue';
import DatePicker from '@/components/shared/customComponents/inputs/date-picker.vue';

import rules from '@/helpers/rules';
import helpers from '@/helpers/helpers';
import languages from '../../locales/languages';
import { useI18n } from 'vue-i18n-bridge';
import { usePermissions } from '@/helpers/composables/permissions.js';
import { useRoute, onBeforeRouteLeave } from 'vue-router/composables';
import { useHubsStore } from '@/store/pinia/hubs.store.js';
import { usePeopleStore } from '@/store/pinia/people.store.js';
import { usePopupDialogStore } from '@/store/pinia/popup-dialog.store.js';
import { ref, computed, onBeforeUnmount } from 'vue';

const form = ref();
const route = useRoute();
const hubsStore = useHubsStore();
const peopleStore = usePeopleStore();
const popupDialogStore = usePopupDialogStore();
const { canUser } = usePermissions();
const { t, tm } = useI18n({ locale: 'en', messages: languages });

// --- Local variables ---
const open = ref(0);
const isFormValid = ref(false);
const isFormDisabled = ref(true);
const unchangedForm = ref(null);
const nextRoute = ref(null);
const billingHubInfo = ref({
  ikNumber: '',
  iksgvxiStartDate: '',
  iksgbvStartDate: '',
  bpaMembership: 1,
});

// --- Methods ---
const getBillingHubInfoById = async () => {
  try {
    billingHubInfo.value = await hubsStore.getBillingHubInfoById(
      route.params.hubId
    );
    helpers.convertNullToEmptyStringInObject(billingHubInfo.value);
    form.value.resetValidation();
  } catch (error) {
    console.log(error);
  }
};

const updateBillingHubInfoById = async () => {
  try {
    const payload = {
      hubId: route.params.hubId,
      billingHubInfo: billingHubInfo.value,
    };
    await hubsStore.updateBillingHubInfoById(payload);
    await getBillingHubInfoById();
    assignFormData();
  } catch (error) {
    console.log(error);
  }
  toggleDisabled();
};

const toggleDiscardChangesDialog = (next) => {
  const actionBtn = () => {
    discardChanges();
    if (nextRoute.value) next();
  };

  const params = {
    type: 'confirmation',
    title: t('Action_.dialog.saveChanges.title'),
    message: t('Action_.dialog.saveChanges.body'),
    actionBtnText: t('Action_.buttons.proceed'),
    actionBtn: actionBtn,
  };

  popupDialogStore.params = params;
  popupDialogStore.displayDialog = true;
};

const isFormAltered = () => {
  if (unchangedForm.value) {
    return !helpers.compareTwoObjects(
      billingHubInfo.value,
      unchangedForm.value
    );
  }
  return false;
};

const assignFormData = () => {
  unchangedForm.value = { ...{}, ...billingHubInfo.value };
};

const clearNextRouteData = () => {
  nextRoute.value = null;
};

const discardChanges = () => {
  billingHubInfo.value = unchangedForm.value;
  toggleDisabled();
};

const toggleDisabled = () => {
  assignFormData();
  form.value.validate();
  isFormDisabled.value = !isFormDisabled.value;
};

const displayConfirmationDialog = () => {
  clearNextRouteData();
  if (isFormAltered()) {
    toggleDiscardChangesDialog();
  } else {
    toggleDisabled();
  }
};

// --- Computed ---
const selectBpaMembershipType = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectBpaMembershipTypes'),
    peopleStore.peopleResources?.bpaMembershipTypes
  );
});

// --- Lifecycle hooks ---
getBillingHubInfoById();

onBeforeUnmount(() => {
  clearNextRouteData();
});

// --- Routing guard ---
onBeforeRouteLeave((to, _, next) => {
  clearNextRouteData();
  if (!isFormAltered()) {
    nextRoute.value = to;
  }
  if (nextRoute.value) {
    next();
  } else {
    nextRoute.value = to;
    toggleDiscardChangesDialog(next);
  }
});
</script>

<style scoped lang="scss"></style>
