var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-expansion-panels',{staticClass:"shadow-regular",attrs:{"data-testid":"hub-profile-operations-billing-hub-container","value":_setup.open,"flat":""}},[_c('v-expansion-panel',{staticClass:"rounded-lg pa-2"},[_c('v-expansion-panel-header',{staticClass:"heading-h3",scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"data-testid":"hub-profile-operations-billing-hub-chevron-icon","color":"#212121"}},[_vm._v(" mdi-chevron-down ")])]},proxy:true}])},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"mr-2",attrs:{"data-testid":"hub-profile-operations-billing-hub-title"}},[_vm._v(" "+_vm._s(_setup.t('Title_.subTitle.billingHub'))+" ")])])]),_c('v-expansion-panel-content',[_c('v-form',{ref:"form",staticClass:"mt-4",attrs:{"data-testid":"hub-profile-operations-billing-hub-form"},model:{value:(_setup.isFormValid),callback:function ($$v) {_setup.isFormValid=$$v},expression:"isFormValid"}},[_c('v-container',{staticClass:"pa-0"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c(_setup.RegularTextInput,{attrs:{"data-testid":"hub-profile-operations-billing-hub-ik-number-field","label":_setup.t('Msg_.ikNumber') + '*',"disabled":_setup.isFormDisabled,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.ikNumber')} ${_setup.t('Msg_.rules.isRequired')}`
                  ),
                  _setup.rules.totalSize(9, _setup.t('Msg_.rules.mustHaveNineDigits')),
                ]},model:{value:(_setup.billingHubInfo.ikNumber),callback:function ($$v) {_vm.$set(_setup.billingHubInfo, "ikNumber", $$v)},expression:"billingHubInfo.ikNumber"}})],1)],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.DatePicker,{staticClass:"pr-3",attrs:{"data-testid":"hub-profile-operations-billing-hub-ik-sgb-xi-date-picker","disabled":_setup.isFormDisabled,"label":_setup.t('Msg_.ikSgbXIStartDate') + '*',"placeholder":_setup.t('Msg_.datePlaceholder'),"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.ikSgbXIStartDate')} ${_setup.t(
                      'Msg_.rules.isRequired'
                    )}`
                  ),
                ]},model:{value:(_setup.billingHubInfo.iksgvxiStartDate),callback:function ($$v) {_vm.$set(_setup.billingHubInfo, "iksgvxiStartDate", $$v)},expression:"billingHubInfo.iksgvxiStartDate"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.DatePicker,{staticClass:"pl-3",attrs:{"data-testid":"hub-profile-operations-billing-hub-operation-ik-sgb-v-date-picker","disabled":_setup.isFormDisabled,"label":_setup.t('Msg_.ikSgbVStartDate') + '*',"placeholder":_setup.t('Msg_.datePlaceholder'),"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.ikSgbVStartDate')} ${_setup.t(
                      'Msg_.rules.isRequired'
                    )}`
                  ),
                ]},model:{value:(_setup.billingHubInfo.iksgbvStartDate),callback:function ($$v) {_vm.$set(_setup.billingHubInfo, "iksgbvStartDate", $$v)},expression:"billingHubInfo.iksgbvStartDate"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c(_setup.DropdownInput,{attrs:{"data-testid":"hub-profile-operations-billing-hub-bpa-membership-dropdown","label":_setup.t('Msg_.bpaMembership') + '*',"items":_setup.selectBpaMembershipType,"disabled":_setup.isFormDisabled,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.bpaMembership')} ${_setup.t('Msg_.rules.isRequired')}`
                  ),
                ]},model:{value:(_setup.billingHubInfo.bpaMembership),callback:function ($$v) {_vm.$set(_setup.billingHubInfo, "bpaMembership", $$v)},expression:"billingHubInfo.bpaMembership"}})],1)],1)],1),(_setup.canUser(['Hubs.UpdateOperationsBillingHub']))?_c('v-container',{staticClass:"px-0 pt-6 pb-2"},[_c('v-row',[(_setup.isFormDisabled)?_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"main-action-btn",attrs:{"data-testid":"hub-profile-operations-billing-hub-edit-info-btn"},on:{"click":function($event){return _setup.toggleDisabled()}}},[_vm._v(" "+_vm._s(_setup.t('Action_.buttons.editInformation'))+" ")])],1):_vm._e(),(!_setup.isFormDisabled)?_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"main-action-variant-btn",attrs:{"data-testid":"hub-profile-operations-billing-hub-cancel-btn"},on:{"click":function($event){return _setup.displayConfirmationDialog()}}},[_vm._v(" "+_vm._s(_setup.t('Action_.buttons.cancel'))+" ")]),_c('v-btn',{staticClass:"main-action-btn ml-2",attrs:{"data-testid":"hub-profile-operations-billing-hub-save-changes-btn","disabled":!_setup.isFormValid},on:{"click":function($event){return _setup.updateBillingHubInfoById()}}},[_vm._v(" "+_vm._s(_setup.t('Action_.buttons.saveChanges'))+" ")])],1):_vm._e()],1)],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }