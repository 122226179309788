var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',{attrs:{"data-testid":"hub-profile-operations-change-to-billing-hub-dialog","value":true,"retain-focus":false,"persistent":"","width":"750"}},[_c('v-card',{staticClass:"py-6 px-8"},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"heading-h3 mb-3 mt-4",attrs:{"data-testid":"hub-profile-operations-change-to-billing-hub-title"}},[_vm._v(" "+_vm._s(_setup.handleDialogTitle)+" ")])]),_c('v-form',{ref:"form",staticClass:"mt-4",attrs:{"data-testid":"hub-profile-operations-change-to-billing-hub-form"},model:{value:(_setup.isFormValid),callback:function ($$v) {_setup.isFormValid=$$v},expression:"isFormValid"}},[_c('v-container',{staticClass:"pa-0"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c(_setup.RegularTextInput,{attrs:{"data-testid":"hub-profile-operations-change-to-billing-hub-ik-number-field","label":_setup.t('Msg_.ikNumber') + '*',"rules":[
                _setup.rules.fieldRequired(
                  `${_setup.t('Msg_.ikNumber')} ${_setup.t('Msg_.rules.isRequired')}`
                ),
                _setup.rules.totalSize(9, _setup.t('Msg_.rules.mustHaveNineDigits')),
              ]},model:{value:(_setup.billingHubInfo.ikNumber),callback:function ($$v) {_vm.$set(_setup.billingHubInfo, "ikNumber", $$v)},expression:"billingHubInfo.ikNumber"}})],1)],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c(_setup.DatePicker,{attrs:{"data-testid":"hub-profile-operations-change-to-billing-hub-ik-sgb-xi-date-picker","label":_setup.t('Msg_.ikSgbXIStartDate') + '*',"placeholder":_setup.t('Msg_.datePlaceholder'),"rules":[
                _setup.rules.fieldRequired(
                  `${_setup.t('Msg_.ikSgbXIStartDate')} ${_setup.t(
                    'Msg_.rules.isRequired'
                  )}`
                ),
              ]},model:{value:(_setup.billingHubInfo.iksgvxiStartDate),callback:function ($$v) {_vm.$set(_setup.billingHubInfo, "iksgvxiStartDate", $$v)},expression:"billingHubInfo.iksgvxiStartDate"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c(_setup.DropdownInput,{attrs:{"data-testid":"hub-profile-operations-change-to-billing-hub-bpa-membership-dropdown","label":_setup.t('Msg_.bpaMembership') + '*',"items":_setup.selectBpaMembershipType,"rules":[
                _setup.rules.fieldRequired(
                  `${_setup.t('Msg_.bpaMembership')} ${_setup.t('Msg_.rules.isRequired')}`
                ),
              ]},model:{value:(_setup.billingHubInfo.bpaMembership),callback:function ($$v) {_vm.$set(_setup.billingHubInfo, "bpaMembership", $$v)},expression:"billingHubInfo.bpaMembership"}})],1)],1),(_vm.isSubHubToBillingHub)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"body-text-3 mb-2",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_setup.t('Msg_.turnSubhubIntoBillingHubMessage'))+" ")])],1):_vm._e()],1),_c('v-container',{staticClass:"px-0 pt-6 pb-2"},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"main-action-variant-btn",attrs:{"data-testid":"hub-profile-operations-change-to-billing-hub-cancel-btn"},on:{"click":function($event){return _setup.closeChangeToBillingHubDialog()}}},[_vm._v(" "+_vm._s(_setup.t('Action_.buttons.cancel'))+" ")]),_c('v-btn',{staticClass:"main-action-btn ml-2",attrs:{"data-testid":"hub-profile-operations-change-to-billing-hub-save-changes-btn","disabled":!_setup.isFormValid},on:{"click":function($event){return _setup.addBillingHubInfo()}}},[_vm._v(" "+_vm._s(_setup.t('Action_.buttons.confirm'))+" ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }