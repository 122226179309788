<template>
  <v-expansion-panels
    data-testid="hub-profile-operations-total-weekly-working-hours-container"
    class="shadow-regular"
    :value="open"
    flat
  >
    <v-expansion-panel class="rounded-lg pa-2">
      <v-expansion-panel-header class="heading-h3 mb-3">
        <div class="d-flex align-center">
          <span
            data-testid="hub-profile-operations-total-weekly-working-hours-title"
            class="mr-2"
          >
            {{ t('Title_.subTitle.totalWeeklyWorkingHours') }}
          </span>
          <v-tooltip top color="ashLight">
            <template #activator="{ on, attrs }">
              <v-icon
                data-testid="hub-profile-operations-total-weekly-working-hours-info-icon"
                color="blueRegular"
                dark
                v-bind="attrs"
                v-on="on"
                small
              >
                mdi-information-outline
              </v-icon>
            </template>
            <div
              data-testid="hub-profile-operations-total-weekly-working-hours-tooltip"
              class="d-flex flex-column pa-2"
            >
              <span class="tooltip-text body-text-3">
                {{ `${t('Msg_.employeeStatusIncluded')}:` }}
              </span>
              <div class="d-flex align-center">
                <v-icon color="#212121" dark small>
                  mdi-check-circle-outline
                </v-icon>
                <span class="tooltip-text body-text-4 ml-1">{{
                  `${t('Msg_.active')}, ${t('Msg_.offboarding')}`
                }}</span>
              </div>
              <div class="d-flex align-center">
                <v-icon color="#212121" dark small>
                  mdi-close-circle-outline
                </v-icon>
                <span class="tooltip-text body-text-4 ml-1">{{
                  `${t('Msg_.starter')}, ${t('Msg_.inactive')}, ${t(
                    'Msg_.exited'
                  )}`
                }}</span>
              </div>
            </div>
          </v-tooltip>
        </div>
        <template #actions>
          <v-icon
            data-testid="hub-profile-operations-total-weekly-working-hours-chevron-icon"
            color="#212121"
          >
            mdi-chevron-down
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-container>
          <v-row
            v-for="(role, index) in hubTotalWeeklyWorkingHours"
            :key="index"
            class="element-border"
          >
            <v-col
              :data-testid="`hub-profile-operations-total-weekly-working-hours-role-${index}`"
              cols="9"
              class="px-0 body-text-4"
            >
              {{ getQualificationType(role.jobTitle) }}
            </v-col>
            <v-col
              :data-testid="`hub-profile-operations-total-weekly-working-hours-value-${index}`"
              cols="3"
              class="px-0 text-right heading-h4"
            >
              {{ getTotalHours(role.weeklyWorkingHours) }}
            </v-col>
          </v-row>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script setup>
import helpers from '@/helpers/helpers';
import languages from '../../locales/languages';
import { useI18n } from 'vue-i18n-bridge';
import { useRoute } from 'vue-router/composables';
import { useHubsStore } from '@/store/pinia/hubs.store.js';
import { ref } from 'vue';

const route = useRoute();
const hubsStore = useHubsStore();
const { t, tm } = useI18n({ locale: 'en', messages: languages });

// --- Local variables ---
const open = ref(0);
const hubTotalWeeklyWorkingHours = ref([
  { jobTitle: null, weeklyWorkingHours: null },
]);

// --- Methods ---
const getHubTotalWeeklyWorkingHoursById = async () => {
  try {
    hubTotalWeeklyWorkingHours.value =
      await hubsStore.getHubTotalWeeklyWorkingHoursById(route.params.hubId);
  } catch (error) {
    console.log(error);
  }
};

const getQualificationType = (jobTitle) => {
  return helpers.getTranslationById(
    tm('Action_.selectQualificationType'),
    jobTitle
  );
};

const getTotalHours = (totalHours) => {
  return `${totalHours ? totalHours : 0}${t('Msg_.hoursAbbreviation')}`;
};

// --- Lifecycle hooks ---
getHubTotalWeeklyWorkingHoursById();
</script>

<style scoped lang="scss">
.element-border {
  border-top: 1px solid $ash-regular;
  border-radius: 4px;
}

.tooltip-text {
  color: #212121;
}
</style>
