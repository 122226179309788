<template>
  <div>
    <HubProfileOperationsGeneral
      v-if="canUser(['Hubs.ReadOperationsGeneral'])"
      class="mb-8"
    />
    <HubProfileOperationsBillingHub
      v-if="canUser(['Hubs.ReadOperationsBillingHub']) && isBillingHub"
      class="mb-8"
    />
    <HubProfileOperationsTotalWeeklyWorkingHours
      v-if="canUser(['Hubs.ReadOperationsWorkingHours'])"
      class="mb-8"
    />
    <HubProfileOperationsInsuranceContractEmployees
      v-if="canUser(['Hubs.ReadOperationsInsurance']) && isBillingHub"
      class="mb-8"
    />
  </div>
</template>

<script setup>
import HubProfileOperationsBillingHub from './hub-profile-operations-billing-hub.vue';
import HubProfileOperationsGeneral from './hub-profile-operations-general.vue';
import HubProfileOperationsInsuranceContractEmployees from './hub-profile-operations-insurance-contract-employees.vue';
import HubProfileOperationsTotalWeeklyWorkingHours from './hub-profile-operations-total-weekly-working-hours.vue';

import { useHubsStore } from '@/store/pinia/hubs.store.js';
import { usePermissions } from '@/helpers/composables/permissions.js';
import { computed } from 'vue';

const hubsStore = useHubsStore();
const { canUser } = usePermissions();

// --- Computed ---
const isBillingHub = computed(() => {
  return hubsStore.hubInfo.hubType === 1;
});
</script>
