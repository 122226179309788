<template>
  <v-expansion-panels
    data-testid="hub-profile-operations-insurance-contract-employees-container"
    class="shadow-regular"
    :value="open"
    flat
  >
    <v-expansion-panel class="rounded-lg pa-2">
      <v-expansion-panel-header class="heading-h3 mb-2">
        <div class="d-flex align-center">
          <span
            data-testid="hub-profile-operations-insurance-contract-employees-title"
            class="mr-2"
          >
            {{ t('Title_.subTitle.insuranceContractEmployees') }}
          </span>
          <v-tooltip top color="ashLight">
            <template #activator="{ on, attrs }">
              <v-icon
                data-testid="hub-profile-operations-insurance-contract-employees-info-icon"
                color="blueRegular"
                dark
                v-bind="attrs"
                v-on="on"
                small
              >
                mdi-information-outline
              </v-icon>
            </template>
            <div
              data-testid="hub-profile-operations-insurance-contract-employees-tooltip"
              class="d-flex flex-column pa-2"
            >
              <span class="tooltip-text body-text-3">
                {{ `${t('Msg_.employeeStatusIncluded')}:` }}
              </span>
              <div class="d-flex align-center">
                <v-icon color="#212121" dark small>
                  mdi-check-circle-outline
                </v-icon>
                <span class="tooltip-text body-text-4 ml-1">{{
                  `${t('Msg_.starter')}, ${t('Msg_.active')}, ${t(
                    'Msg_.inactive'
                  )}, ${t('Msg_.offboarding')}, ${t('Msg_.exited')}`
                }}</span>
              </div>
              <div class="d-flex align-center">
                <v-icon color="#212121" dark small>
                  mdi-close-circle-outline
                </v-icon>
                <span class="tooltip-text body-text-4 ml-1">-</span>
              </div>
            </div>
          </v-tooltip>
        </div>
        <template #actions>
          <v-icon
            data-testid="hub-profile-operations-insurance-contract-employees-chevron-icon"
            color="#212121"
          >
            mdi-chevron-down
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div v-if="isFormDisabled">
          <div
            v-for="(employee, index) in hubInsuranceContractEmployees"
            :key="employee.employeeId"
          >
            <HubProfileOperationsInsuranceContractEmployeesListItem
              class="pb-2"
              :employee="employee"
              :index="index"
            />
          </div>
        </div>
        <v-form
          v-if="!isFormDisabled"
          data-testid="hub-profile-operations-insurance-contract-employees-form"
          v-model="isFormValid"
          ref="form"
          class="mt-4"
        >
          <v-container class="pa-0">
            <v-row dense>
              <v-col cols="12">
                <Combobox
                  class="custom-combobox"
                  data-testid="hub-profile-operations-insurance-contract-employees-add-employees-combobox"
                  :label="t('Msg_.addInsuranceContractEmployees')"
                  :items="selectEmployeesWithInsuranceContract"
                  v-model="employeesToAddInsuranceContract"
                  :clearable="false"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-container
          v-if="hubInsuranceContractEmployees.length === 0"
          class="pa-0 mt-4 body-text-2"
        >
          <v-row
            dense
            data-testid="hub-profile-operations-insurance-contract-employees-empty-list-message"
          >
            <v-col>
              {{ t('Msg_.emptyListMessage') }}
            </v-col>
          </v-row>
        </v-container>
        <v-container
          v-if="canUser(['Hubs.UpdateOperationsInsurance'])"
          class="px-0 mt-8 pb-2"
        >
          <v-row>
            <v-col v-if="isFormDisabled" cols="12" class="d-flex justify-end">
              <v-btn
                data-testid="hub-profile-operations-insurance-contract-employees-edit-info-btn"
                class="main-action-btn"
                @click="toggleDisabled()"
              >
                {{ t('Action_.buttons.editInformation') }}
              </v-btn>
            </v-col>
            <v-col v-if="!isFormDisabled" cols="12" class="d-flex justify-end">
              <v-btn
                data-testid="hub-profile-operations-insurance-contract-employees-cancel-btn"
                class="main-action-variant-btn"
                @click="displayConfirmationDialog()"
              >
                {{ t('Action_.buttons.cancel') }}
              </v-btn>
              <v-btn
                data-testid="hub-profile-operations-insurance-contract-employees-save-changes-btn"
                class="main-action-btn ml-2"
                :disabled="!isFormValid"
                @click="updateHubInsuranceContractEmployeesById()"
              >
                {{ t('Action_.buttons.saveChanges') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script setup>
import HubProfileOperationsInsuranceContractEmployeesListItem from './hub-profile-operations-insurance-contract-employees-list-item.vue';
import Combobox from '@/components/shared/customComponents/inputs/combobox-input.vue';

import helpers from '@/helpers/helpers';
import languages from '../../locales/languages';
import { useI18n } from 'vue-i18n-bridge';
import { usePermissions } from '@/helpers/composables/permissions.js';
import { useRoute, onBeforeRouteLeave } from 'vue-router/composables';
import { useHubsStore } from '@/store/pinia/hubs.store.js';
import { usePopupDialogStore } from '@/store/pinia/popup-dialog.store.js';
import { ref, computed, onBeforeUnmount } from 'vue';

const form = ref();
const route = useRoute();
const hubsStore = useHubsStore();
const popupDialogStore = usePopupDialogStore();
const { canUser } = usePermissions();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Local variables ---
const open = ref(0);
const isFormValid = ref(false);
const isFormDisabled = ref(true);
const unchangedForm = ref(null);
const nextRoute = ref(null);
const hubEmployees = ref([]);
const hubInsuranceContractEmployees = ref([
  {
    employeeId: null,
    fullName: '',
    status: null,
    jobTitle: null,
    weeklyWorkingHours: null,
    startDate: '',
    employeeNumber: null,
  },
]);
const employeesToAddInsuranceContract = ref([]);

// --- Methods ---
const getHubInsuranceContractEmployeesById = async () => {
  try {
    hubInsuranceContractEmployees.value =
      await hubsStore.getHubInsuranceContractEmployeesById(route.params.hubId);
    convertEmployeesForCombobox();
  } catch (error) {
    console.log(error);
  }
};

const updateHubInsuranceContractEmployeesById = async () => {
  try {
    const payload = {
      hubId: route.params.hubId,
      employeeIds: getHubEmployeeIdsForPayload(),
    };
    await hubsStore.updateHubInsuranceContractEmployeesById(payload);
    await getHubInsuranceContractEmployeesById();
    assignFormData();
  } catch (error) {
    console.log(error);
  }
  toggleDisabled();
};

const getHubEmployeesById = async () => {
  try {
    hubEmployees.value = await hubsStore.getHubEmployeesById(
      route.params.hubId
    );
  } catch (error) {
    console.log(error);
  }
};

const convertEmployeesForCombobox = () => {
  employeesToAddInsuranceContract.value =
    hubInsuranceContractEmployees.value.map((employee) => ({
      value: employee.employeeId,
      text: employee.fullName,
    }));
};

const getHubEmployeeIdsForPayload = () => {
  return employeesToAddInsuranceContract.value.map(
    (employee) => employee.value
  );
};

const toggleDiscardChangesDialog = (next) => {
  const actionBtn = () => {
    discardChanges();
    if (nextRoute.value) next();
  };

  const params = {
    type: 'confirmation',
    title: t('Action_.dialog.saveChanges.title'),
    message: t('Action_.dialog.saveChanges.body'),
    actionBtnText: t('Action_.buttons.proceed'),
    actionBtn: actionBtn,
  };

  popupDialogStore.params = params;
  popupDialogStore.displayDialog = true;
};

const isFormAltered = () => {
  if (unchangedForm.value) {
    return !helpers.compareTwoObjects(
      employeesToAddInsuranceContract.value,
      unchangedForm.value
    );
  }
  return false;
};

const assignFormData = () => {
  unchangedForm.value = [...[], ...employeesToAddInsuranceContract.value];
};

const clearNextRouteData = () => {
  nextRoute.value = null;
};

const discardChanges = () => {
  employeesToAddInsuranceContract.value = unchangedForm.value;
  toggleDisabled();
};

const toggleDisabled = () => {
  assignFormData();
  isFormDisabled.value = !isFormDisabled.value;
};

const displayConfirmationDialog = () => {
  clearNextRouteData();
  if (isFormAltered()) {
    toggleDiscardChangesDialog();
  } else {
    toggleDisabled();
  }
};

const initializeComponent = () => {
  getHubInsuranceContractEmployeesById();
  getHubEmployeesById();
};

// --- Computed ---
const selectEmployeesWithInsuranceContract = computed(() => {
  return hubEmployees.value.map((employee) => {
    return {
      value: employee.id,
      text: employee.name,
    };
  });
});

// --- Lifecycle hooks ---
initializeComponent();

onBeforeUnmount(() => {
  clearNextRouteData();
});

// --- Routing guard ---
onBeforeRouteLeave((to, _, next) => {
  clearNextRouteData();
  if (!isFormAltered()) {
    nextRoute.value = to;
  }
  if (nextRoute.value) {
    next();
  } else {
    nextRoute.value = to;
    toggleDiscardChangesDialog(next);
  }
});
</script>

<style scoped lang="scss">
.tooltip-text {
  color: #212121;
}
</style>
