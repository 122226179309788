<template>
  <v-dialog
    data-testid="hub-profile-operations-change-to-billing-hub-dialog"
    :value="true"
    :retain-focus="false"
    persistent
    width="750"
  >
    <v-card class="py-6 px-8">
      <div class="d-flex align-center">
        <span
          data-testid="hub-profile-operations-change-to-billing-hub-title"
          class="heading-h3 mb-3 mt-4"
        >
          {{ handleDialogTitle }}
        </span>
      </div>
      <v-form
        data-testid="hub-profile-operations-change-to-billing-hub-form"
        v-model="isFormValid"
        ref="form"
        class="mt-4"
      >
        <v-container class="pa-0">
          <v-row dense>
            <v-col cols="12">
              <RegularTextInput
                data-testid="hub-profile-operations-change-to-billing-hub-ik-number-field"
                :label="t('Msg_.ikNumber') + '*'"
                v-model="billingHubInfo.ikNumber"
                :rules="[
                  rules.fieldRequired(
                    `${t('Msg_.ikNumber')} ${t('Msg_.rules.isRequired')}`
                  ),
                  rules.totalSize(9, t('Msg_.rules.mustHaveNineDigits')),
                ]"
              />
            </v-col>
          </v-row>
          <v-row dense class="d-flex justify-space-between">
            <v-col cols="12">
              <DatePicker
                data-testid="hub-profile-operations-change-to-billing-hub-ik-sgb-xi-date-picker"
                :label="t('Msg_.ikSgbXIStartDate') + '*'"
                :placeholder="t('Msg_.datePlaceholder')"
                v-model="billingHubInfo.iksgvxiStartDate"
                :rules="[
                  rules.fieldRequired(
                    `${t('Msg_.ikSgbXIStartDate')} ${t(
                      'Msg_.rules.isRequired'
                    )}`
                  ),
                ]"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <DropdownInput
                data-testid="hub-profile-operations-change-to-billing-hub-bpa-membership-dropdown"
                :label="t('Msg_.bpaMembership') + '*'"
                :items="selectBpaMembershipType"
                v-model="billingHubInfo.bpaMembership"
                :rules="[
                  rules.fieldRequired(
                    `${t('Msg_.bpaMembership')} ${t('Msg_.rules.isRequired')}`
                  ),
                ]"
              />
            </v-col>
          </v-row>
          <v-row dense v-if="isSubHubToBillingHub">
            <v-col cols="12" class="body-text-3 mb-2">
              {{ t('Msg_.turnSubhubIntoBillingHubMessage') }}
            </v-col>
          </v-row>
        </v-container>
        <v-container class="px-0 pt-6 pb-2">
          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn
                data-testid="hub-profile-operations-change-to-billing-hub-cancel-btn"
                class="main-action-variant-btn"
                @click="closeChangeToBillingHubDialog()"
              >
                {{ t('Action_.buttons.cancel') }}
              </v-btn>
              <v-btn
                data-testid="hub-profile-operations-change-to-billing-hub-save-changes-btn"
                class="main-action-btn ml-2"
                :disabled="!isFormValid"
                @click="addBillingHubInfo()"
              >
                {{ t('Action_.buttons.confirm') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script setup>
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';
import DropdownInput from '@/components/shared/customComponents/inputs/dropdown-input.vue';
import DatePicker from '@/components/shared/customComponents/inputs/date-picker.vue';

import rules from '@/helpers/rules';
import helpers from '@/helpers/helpers';
import languages from '../../locales/languages';
import { usePeopleStore } from '@/store/pinia/people.store.js';
import { ref, computed, defineEmits, defineProps } from 'vue';
import { useI18n } from 'vue-i18n-bridge';

const peopleStore = usePeopleStore();
const emit = defineEmits([
  'add-billing-hub-info',
  'close-change-to-billing-hub-dialog',
]);
const { t, tm } = useI18n({ locale: 'en', messages: languages });

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  isSubHubToBillingHub: {
    type: Boolean,
    required: false,
    default: false,
  },
});

// --- Local variables ---
const isFormValid = ref(false);
const billingHubInfo = ref({
  ikNumber: '',
  iksgvxiStartDate: '',
  bpaMembership: null,
});

// --- Methods ---
const closeChangeToBillingHubDialog = () => {
  emit('close-change-to-billing-hub-dialog');
};

const addBillingHubInfo = () => {
  emit('add-billing-hub-info', billingHubInfo.value);
};

// --- Computed ---
const handleDialogTitle = computed(() => {
  return props.isSubHubToBillingHub
    ? t('Title_.subTitle.turnSubhubIntoBillingHub')
    : t('Title_.subTitle.createBillingHub');
});

const selectBpaMembershipType = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectBpaMembershipTypes'),
    peopleStore.peopleResources?.bpaMembershipTypes
  );
});
</script>

<style lang="scss" scoped></style>
